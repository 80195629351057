import React from 'react'
import { graphql } from 'gatsby'
import SubHero from '../components/SubHero'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import ProjectSections from '../components/ProjectSections'

const Projects = ({ data }) => (
  <Layout>
    <Helmet
      title="Projects | NEXTGEN Code Company"
      meta={[{ name: 'description', content: 'Software development agency.' }]}
    />
    <SubHero title="Projects" subtitle="" />
    {data.allContentfulProject.edges
      .sort((a, b) => a.projectSpotRanking - b.projectSpotRanking)
      .map(edge => (
        <ProjectSections key={edge.node.title} node={edge.node} />
      ))}
  </Layout>
)

export default Projects

export const ProjectQuery = graphql`
  query ProjectQuery {
    allContentfulProject(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          title
          slug
          type
          projectSpotRanking
          backgroundImage {
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
            resolutions(width: 1080) {
              width
              height
              src
            }
          }
          mockup {
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
